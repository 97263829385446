.event-list-container {
  @include grid-row();
  position: relative;
}

.event-list {
  @include grid-column();
}

.event {
  padding: 2px 5px;
  width: 100%;
}

.event-inner {
  width: 100%;
  height: rem-calc(80);
  color: #fff;
  position: relative;
  transition: 0.6s;
  transform-style: preserve-3d;
  background: #c10e1f;
}

.event-date {
  height: rem-calc(80);
  font-weight: 700;
  border-right: 1px solid #ca3240;
  text-align: center;
  line-height: 1.15;
  padding-top: rem-calc(10);
  @include fixed(80, left);

  .day {
    font-size: rem-calc(32);
  }

  .month {
    font-size: rem-calc(18);
  }
}

.event-title {
  height: rem-calc(80);
  @include fluid(80, right);

  p {
    font-weight: 600;
    line-height: 1.4;
    padding: rem-calc(0 12 0 12);
    hyphens: auto;
    margin: rem-calc(0);
  }

  .event-info {
    font-weight: 400;
    font-size: 12px;
    padding-top: rem-calc(2)
  }

  .label {
    background: none;
    border: 1px solid #fff;
  }
}

.event-title-inner {
  @include vertical-align();
}

.event-details {
  background: #fff;
  margin-left: rem-calc(80);
}

.event-download {
  @include breakpoint(medium) {
    position: relative;
    top: 10px;
    right: 21px;
    z-index: 1;
  }

  .download-link {
    text-align: center;
    @include breakpoint(medium) {
      text-align: right;
    }

    //@include breakpoint(medium) {
    //  padding-right: rem-calc(10);
    //}

    a {
      color: $black;
    }
  }
}

.owl-carousel .owl-nav {
  color: #a8a8a8;
  .owl-prev {
    position: absolute;
    left: -8px;
    top: 32px;
    margin-left: 10px;

    &:hover {
      color: #000;
    }
  }

  .owl-next {
    position: absolute;
    right: -8px;
    top: 32px;
    margin-right: 10px;

    &:hover {
      color: #000;
    }
  }

  .disabled {
    cursor: default;
    display: none;
  }
}

.owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 20px 5px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}
