.gallery {
  .content-wrapper {
    @include grid-layout(2);
    @include grid-row();
    @include breakpoint(medium) {
      @include grid-layout(4);
    }

    margin-top: rem-calc(10);
    margin-bottom: rem-calc(25);

    .column {
      @include grid-column-collapse;
      padding: rem-calc(2);
    }
  }
}

.gallery-album-list {
  .content-wrapper {
    @include grid-layout(1);
    @include grid-row();
    @include breakpoint(medium) {
      @include grid-layout(2);
    }
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(25);

    .column {
      @include grid-column-collapse;
      padding: rem-calc(2);

      p {
        padding: rem-calc(10 10 10 0);
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 5px solid #e9e9e9;
        display: table-cell;
      }

      p:after {
        content: "";
        display: table-cell;
        width: 100%;
      }
    }
  }
}