.news-latest {
  @include grid-row();
  @include grid-layout(1);
  @include breakpoint(medium) {
    @include grid-layout(2);
  }
  @include breakpoint(large) {
    @include grid-layout(3);
  }

  a {
    color: $black;
  }
}

.news-item {
  background: #eaeaea;
  margin-bottom: rem-calc(15);
}

.news-item-image {
  height: rem-calc(160);
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
    transform: translate3d(0,-50%,0);
    margin: 0 auto;
  }
}

.news-item-content {
  padding: rem-calc(25 20);

  h2 {
    text-transform: none;
    font-size: rem-calc(24);
  }

  p {
    font-size: rem-calc(14);
  }
}

.news-detail-teaser {
  font-size: rem-calc(22);
  font-weight: 600;
}
