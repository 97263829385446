$footerTextColor: #afb1b1;
footer {
  color: $footerTextColor;
  padding-top: 250px;
  border-bottom: 1px solid #1f1f1f;
  font-size: rem-calc(14);
  position: relative;

  .footer-image {
    background: url(../Images/footer.png) no-repeat;
    background-position: center top;
    height: 379px;
    width: 100%;
    position: absolute;
    margin-top: -225px;
    //z-index: 5;
  }

  .footer-content {
    background: #2c2c2c;
    padding-bottom: 25px;
    padding-top: 40px;

    .fa-inverse {
      color: #282828;
    }

    .row {
      position: relative;
      //z-index: 10;
    }
  }

  h4 {
    text-transform: uppercase;
    color: $footerTextColor;
  }
}

.footer-left {
  @include grid-column(12);
  @include breakpoint(medium) {
    @include grid-column(6);
  }
  @include breakpoint(large) {
    @include grid-column(4);
    @include grid-column-position(-4);
    //padding-top: 25px;
  }
}

.footer-center {
  @include grid-column(12);
  padding-top: 130px;
  padding-bottom: 25px;

  @include breakpoint(large) {
    @include grid-column(4);
    @include grid-column-position(4);
  }

  a {
    color: $footerTextColor;
  }
}

.footer-right {
  @include grid-column(12);
  @include breakpoint(medium) {
    @include grid-column(6);
  }
  @include breakpoint(large) {
    @include grid-column(4);
    padding-top: 45px;
  }
}

#copyright {
  background: #202020;
  padding: rem-calc(20) 0;
  border-top: 1px solid #414141;
  color: #5b5c5d;

  p {
    margin-bottom: 0;
    font-size: rem-calc(14)
  }
}
