@charset 'utf-8';
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800);

///* open-sans-regular - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: url('../Fonts/OpenSans/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Regular'), local('OpenSans-Regular'),
//  url('../Fonts/OpenSans/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../Fonts/OpenSans/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../Fonts/OpenSans/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
//  url('../Fonts/OpenSans/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../Fonts/OpenSans/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
//
///* open-sans-600 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  src: url('../Fonts/OpenSans/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
//  url('../Fonts/OpenSans/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../Fonts/OpenSans/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../Fonts/OpenSans/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
//  url('../Fonts/OpenSans/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../Fonts/OpenSans/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
//
///* open-sans-700 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: url('../Fonts/OpenSans/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Bold'), local('OpenSans-Bold'),
//  url('../Fonts/OpenSans/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../Fonts/OpenSans/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../Fonts/OpenSans/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
//  url('../Fonts/OpenSans/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../Fonts/OpenSans/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
//
///* open-sans-800 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 800;
//  src: url('../Fonts/OpenSans/open-sans-v15-latin-800.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
//  url('../Fonts/OpenSans/open-sans-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../Fonts/OpenSans/open-sans-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../Fonts/OpenSans/open-sans-v15-latin-800.woff') format('woff'), /* Modern Browsers */
//  url('../Fonts/OpenSans/open-sans-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../Fonts/OpenSans/open-sans-v15-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
//}




@import 'settings';
@import 'foundation';
@import 'motion-ui';

$fa-font-path: "../Fonts" !default;
@import 'font-awesome';
@import 'owl.carousel';
// Magnific Popup
@import 'main';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-table;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

@mixin fixed($width, $direction) {
  position: absolute;
  width: rem-calc($width);
  top: 0;
  @if $direction == left {
    left: 0;
  } @else {
    right: 0;
  }
}

@mixin fluid($width, $direction) {
  @if $direction == left {
    padding-right: rem-calc($width);
  } @else {
    padding-left: rem-calc($width);
  }
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

h1, h3, h4 {
  text-transform: uppercase;
}

header {
  background: $white;
  position: relative;
}

.frontpage {
  background: #eaeaea;

  .off-canvas-content {
    background: #eaeaea;
  }

  #slideshow {
    max-height: rem-calc(500);
    z-index: 0;
    text-align: center;

    .owl-item {
      //height: rem-calc(330);
      @include breakpoint(medium) {
        height: rem-calc(500);
      }
    }
  }
}

.owl-carousel {
  display: block;
}

#slideshow {
  max-height: rem-calc(350);
  overflow: hidden;
  position: relative;
  background: $white;
  z-index: 0;
  text-align: center;

  @include breakpoint(medium) {
    .owl-item {
      height: rem-calc(350);
      position: relative;
    }

    .owl-item img {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0,-50%);
      transform: translate3d(0,-50%,0);
      margin: 0 auto;
    }
  }
}

.top-bar-right ul li {
  & > a  {
    border-top: 2px solid #fff;
  }
  & > a:hover {
    border-top: 2px solid $primary-color;
  }
}

.top-bar-right ul.submenu li {
  & > a {
    border: none;
  }

  & > a:hover {
    color: $primary-color;
  }
}

.menu > li:not(.menu-text) > a {
  line-height: rem-calc(107);
  color: $black;
}

.menu-text {
  padding: 0;
}

.top-nav {
  border-bottom: 1px solid #e9e9e9;
  display: none;

  @include breakpoint(large) {
    display: inherit;
  }

  .menu > li:not(.menu-text) > a {
    line-height: inherit;
    color: #9c9c9c;
    font-size: rem-calc(12);
  }
}

.dropdown.menu {
  font-weight: bold;
  text-transform: uppercase;

  .submenu {
    font-weight: 600;
    text-transform: none;

    & > li {
      width: 80%;
      margin: 0 10%;
    }
  }

  .is-dropdown-submenu-parent.is-down-arrow > a:after {
    top: rem-calc(61);
  }
}

.submenu {
  & > li:not(.menu-text) > a {
    line-height: 1.75;
  }

  & li {
    border-bottom: thin solid #eee;
    //margin: 0 rem-calc(24);
  }

  &.js-dropdown-active {
    position: absolute;
    z-index: 100;
  }
}

.menu-toggle {
  background: $primary-color;
  color: #fff;
  padding: rem-calc(10 15 10 15);
  position: absolute;
  right: rem-calc(25);
  top: rem-calc(25);
  z-index: 5;

  @include breakpoint(medium) {
    top: rem-calc(40);
  }

  &:hover {
    color: #fff;
  }
}

.off-canvas {
  a {
    color: $white;
  }

  .menu > li:not(.menu-text) > a {
    line-height: rem-calc(20);
    font-weight: bold;
    padding: rem-calc(20 15);
  }
}

nav.sidebar {
  //padding: rem-calc(15);
  width: 100%;
  margin-top: rem-calc(90);

  li {
    border-bottom: 1px solid #d5d5d5;
  }

  li:last-child {
    border-bottom: none !important;
  }
}

#logo {
  height: rem-calc(75);
  @include breakpoint(medium) {
    height: rem-calc(110);
  }
}

.white-bg {
  background: #fff;
}

.content {
  padding: rem-calc(30 0);
}

.person {
  @include grid-row();

  @include breakpoint(medium) {
    padding-bottom: rem-calc(35);
  }

  .avatar {
    filter: url('data:image/svg+xml;utf9,<svg xmlns="http://www.w3.org/2000/svg"><filter id="grayscale">    <feColorMatrix type="matrix" values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0"/></filter></svg>#grayscale');
    filter: gray;
    -webkit-filter: grayscale(1);

    &:hover {
      filter: none;
      -webkit-filter: none;
    }

    @include breakpoint(medium) {
      @include grid-column(3);
    }
  }

  .details {
    @include breakpoint(medium) {
      @include grid-column(9);
    }
  }
}

.file-list {
  padding-bottom: rem-calc(50);
}

.file-list-item {
  color: $black;
  display: block;
  width: 100%;
  border-top: 1px solid #eee;
  padding: rem-calc(20 15 20 0);

  span {
    @include breakpoint(medium) {
      font-size: rem-calc(23);
    }
  }

  &:hover, &:active, &:focus {
    background-color: #fafafa;
    color: $black;
  }

  &:last-child, &.last {
    border-bottom: 1px solid #eee;
  }

  .fa-fw {
    width: rem-calc(50);
    @include breakpoint(medium) {
      font-size: 2em;
      width: rem-calc(100);
    }
  }

  .pull-right {
    padding-top: rem-calc(10);
  }
}


.img-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.map {
  width: 100%;
  height: rem-calc(500);
  background: #ccc;
}

.textwithimage {
  figure {
    text-align: center;
    @include breakpoint(medium) {
      float: right !important;
      margin-left: rem-calc(40);
    }
  }
}

.form label {
  color: $black;
}

@import 'components/events.scss';
@import 'components/news.scss';
@import 'components/gallery.scss';
@import 'components/footer.scss';
//@import 'components/fancybox.scss';
